import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyWarren = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Charles Warren</h2>
        <div className={styles.center}><p>The following was transcribed from a phone interview.</p></div>
        <section>
          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>1990-1991</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>I met Robert Davis while teaching at the University of Michigan and he asked me to go be Seaside’s Town Architect. I was leaving the University so I decided to go.</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>Getting the buildings done that I had designed there. They included three houses, started while I was there and finished later, utility buildings, shops along the beach, and a swimming area, many of which are now gone. I also designed Natchez Park after I left the role of Town Architect.</p>

          <p className={styles.bold}>What were common challenges you found while working in Seaside?</p>
          <p>Stopping people from doing stupid things without making them unhappy. These were other people’s plans and work and I had to suggest that they make changes in a delicate enough way that they wouldn’t take it too personally.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>Seaside was an early exemplar; DPZ and others learned from everything in Seaside, even what didn’t work. It is less perfect than later New Urbanist projects, which makes it more interesting: more style than places with style based codes like Celebration, more variation that many later towns. It is full of lessons, both good and bad.</p>

          <p className={styles.bold}>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>You can’t be an architect without being an Urbanist. But what is a New Urbanist anyway?</p>
          <p>Seaside was an early turning point in the rediscovery of theories of town planning. It remains the most complete and largest example of New Urbanism today. It is also part of a much larger tradition of holiday town building, suburban planning and small town planning, which had been lost since the mid-19th century. Anyone interested in urbanism today needs to visit Seaside, whether they like it or not.</p>
        <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyWarren
